.footer{ 
    height: 50px;
    background-color: var(--secondary-bg-colour);
    color: var(--main-bg-colour);
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 70px;
}
.footer p{
  margin: 0;
}