.h1{
    font-size: 38px;
    font-weight: 300;
    margin: 5px;
}
.h2{
    font-size: 34px;
    font-weight: 300;
    margin: 5px;
    margin-bottom: 50px;
}
.h3{
    font-size: 30px;
    color: var(--main-accent-colour);
    font-weight: 200;
    margin: 5px;
    text-align: left;
}
.h4{
    font-size: 26px;
    font-weight: 200;
    margin: 7px 7px 7px 0;
    padding-left: 5px;
    width: 100%;
    text-align: left;
    border-bottom: 5px solid var(--main-accent-colour);
}
.h5{
    font-size: 28px;
    color: #0dd8eb;
    font-weight: 400;
    margin: 5px;
    margin-bottom: 40px;
}
.h6{
    font-size: 24px;
    color: var(--main-accent-colour);
    font-weight: 300;
    text-align: left;
    margin-bottom: 0;
}
.p{
    text-align: left;
    font-weight: 300;
    font-size: 18px;
    margin: 0;
}

@media (min-width:801px){
.h1{
    font-size: 44px;
}
.h2{
    font-size: 42px;
}
.h3{
    font-size: 36px;
}
.h4{
    font-size: 32px;
}
.p{
    font-size: 20px;
}
}