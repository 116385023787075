.accent-box-container{

}
.accent-box {
    display: none;
}

@media (min-width:1280px) {
    .accent-box {
        display: block;
        background-color: var(--secondary-bg-colour);
        position: absolute;
        z-index: -1;
        transform: rotate(20deg);
    }
    .accent-box.less {
        display: block;
        background-color: var(--secondary-bg-colour);
        position: absolute;
        z-index: -1;
        transform: rotate(-4deg);
    }
}