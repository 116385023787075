.contact {
    margin: 0;
    margin-bottom: 100px;
    flex-direction: column;
    width: 90%;
    align-self: center;
    z-index: 1;
}

@media (min-width:801px){
    .contact {
        width: 80%;
        margin: 100px 0;
    }
}