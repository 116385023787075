.course-level{
    margin-bottom: 30px;
    transition: 400ms;

}
.course-level-btn-container{
    display: flex;
    justify-content: right;
    margin-top: 10px;
}
.course-info-list{
    text-align: left;
    font-weight: 300;
    font-size: 20px;
    margin: 0;
}

@media (min-width:801px){
    
}