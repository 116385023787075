.btn-main{
    padding: 15px 35px;
    color: var(--main-bg-colour);
    font-size: 20px;
    margin: 7px;
    transition: 250ms;
}
.primary:disabled,
.secondary:disabled,
.tertiary:disabled,
.primary:disabled:hover,
.secondary:disabled:hover,
.tertiary:disabled:hover{
    background-color: #424242;
    cursor: not-allowed !important;
    color: #808080;
}
.primary {
    background-color: var(--main-accent-colour);
    border: none;
}
.primary:hover {
    background-color: white;
    border: none;
    cursor: pointer;
}
.secondary{
    background-color: #ffffff00;
    color: white;
    border: 3px solid var(--main-accent-colour);
    padding: 12px 35px;
}
.tertiary{
    background-color: var(--secondary-bg-colour);
    color: white;
    border: none;
}
.tertiary:hover, 
.secondary:hover {
    background-color: var(--main-accent-colour);
    color: var(--main-bg-colour);
    cursor: pointer;
}