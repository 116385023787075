.sponsor-page{
    margin: 100px 0;
    display: flex;
    flex-direction: column;
    width: 90%;
    align-self: center;
    z-index: 1;
}

.expand-sponsor{
    font-size: 20px;
    font-weight: 300;
    margin: 7px 7px 25px 7px;
    padding: 5px;
    padding-left: 20px;
    /* width: 100%; */
    text-align: left;
    border: 3px solid var(--main-accent-colour);
    transition: 200ms;
}
.expand-sponsor:hover{
    background-color: rgba(158, 158, 158, 0.247);
    cursor: pointer;
}
.expand-content{
    margin: 7px;
    padding:  10px 20px;
    border: 4px solid var(--main-accent-colour);
   margin-top: -29px;
   margin-bottom: 20px;
}


@media (min-width:801px){
    .sponsor-page {
        width: 80%;
    }
    .expand-sponsor{
    font-size: 22px;
}
}