

.course-guide{
    display: none;
}

@media (min-width:1280px){
    .course-guide{
        display: block;
        margin-left: 125px;
        /* width: 420px; */
        align-self: center;
    }
    .guide-stick{
        height: 30px;
        width: 3px;
        background-color: var(--main-accent-colour);
        margin: auto;
    }
    .guide-number{
        font-size: 22px;
        border: 3px solid var(--main-accent-colour);
        width: 30px;
        padding: 10px;
        border-radius: 500px;
        margin: auto;

    }
    .guide-content{
        font-size: 20px;
        font-weight: 300;
    }
}