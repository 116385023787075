.course-book{
    display: flex;
    flex-direction: column;
}
.course-book-info select{
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 50px;
    font-size: 20px;
    font-weight: 200;
    background-color: #ffffff42;
    color: white;
    border: 2px solid var(--main-accent-colour);
    padding: 0 10px;
    margin: 10px 0;
}
.course-book-info ul{
    text-align: left;
    font-weight: 300;
    font-size: 18px;
    margin: 0;
}
.course-book-form{
    border-top: 1px dashed var(--main-accent-colour);
    margin-top: 20px;
}

@media (min-width:801px){
    .course-book-info ul{
        text-align: left;
        font-weight: 300;
        font-size: 22px;
        margin: 0;
    }
    .course-book-form input{
        width: 97%
    }
}