.nav-bar{
    z-index: 4;
    display: flex;
    top: 0;
    height: 70px;
    background-color: var(--main-accent-colour);
    box-shadow: 0px 0px 17px 3px #00000049;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    padding: 0px 30px;
}
.nav-logo img{
    width: 175px;
    margin-top: 5px;
    filter: drop-shadow(0px 4px 4px rgba(34, 34, 34, 0.705));
    height: 100px;
    background-color: white;
    object-fit: cover;
}
.nav-links{
    display: flex;
}
.nav-links p{
    margin: 0 10px;
    font-size: 19px;
    color: var(--main-bg-colour);
    cursor: pointer;
}

@media (max-width:801px){
    .nav-logo img{
        width: 90px;
        height: 70;
    }
    .nav-bar{
        padding: 0px;
        justify-content: space-around;
    }
    .nav-links p{
        margin: 0 5px;
        font-size: 18px;
        color: var(--main-bg-colour);
        cursor: pointer;
    }
}