@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

:root {
  --main-bg-colour: #1D1E4E;
  --secondary-bg-colour: #0b9eac;
  --main-accent-colour: #FCCF12;
}

.App {
  font-family: 'Poppins', sans-serif;
  background-color: var(--main-bg-colour);
  background: linear-gradient(to bottom right, var(--main-bg-colour), #181a83);
  display: flex;
  flex-direction: column;
  text-align: center;
  color: antiquewhite;
  position: relative;
  /* overflow: hidden; */
  clip-path: inset(0);
  clip: rect(0px, auto, auto, 0px);
  contain: paint;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--secondary-bg-colour);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-accent-colour); 
}
