.form-container input,
.form-container textarea{
    font-family: 'Poppins', sans-serif;
    width: 95%;;
    padding: 10px 0;
    background-color: #ffffff00;
    border: 5px solid var(--main-accent-colour);
    margin-bottom: 15px;
    color: white;
    font-size: 20px;
    font-weight: 300;
    padding-left: 10px;
    transition: 200ms;
    resize: none;
}

.form-container input[type='number'] {
    -moz-appearance:textfield;
}

.form-container input::-webkit-outer-spin-button,
.form-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.form-container input::placeholder,
.form-container textarea::placeholder{
    color: #ffffffc2;
    font-size: 18px;
}

.form-container input:hover,
.form-container textarea:hover,
.form-container input:focus,
.form-container textarea:focus{
    background-color: rgba(158, 158, 158, 0.247);
}

.form-error {
    height: 20px;
}
.form-error p{
    display: flex;
    justify-content: right;
    margin: 0;
    margin-right: 5%;
}

@media (min-width:801px){
    .form-container input,
    .form-container textarea{
        width: 65%;
        font-size: 22px;
        padding: 10px 0;
        padding-left: 3%;
    }
    .form-error p{
        margin-right: 18%;
    }
}
