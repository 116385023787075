.home {
    height: 100vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    text-align: left;
    z-index: 1;
}
.home-btn-container{
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-top: 20px;
}
.home-image{
    display: none;
}

@media (min-width:801px){
    .home-btn-container{
        flex-direction: row;
    }
    .home{
        width: 500px;
        align-self: inherit;
        margin-left: 200px;
    }
}
@media (min-width:1280px){
    .home-image { 
        display: block;
        position: absolute;
        top: 34vh;
        right: 50px;
        width: 60vw;
    }
}