.courses {
    width: 90%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    
}
.courses-container{
    display: flex;
    width: fit-content;
}

@media (min-width:801px){
    .courses {
        width: auto;
        margin-top: 100px;
        margin: 100px 100px 100px 200px;
        z-index: 0;
    
    }
    .courses-container{
        display: flex;
        width: fit-content;
    }
}