.info-modal {
        position: fixed;
        z-index: 2;
        background-color: #ffffff25;
        width: 100%;
        height: 100vh;
        transition: 700ms;
        backdrop-filter: blur(15px);
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden
    }
    .info-modal-content{
        width: 80%;
        height: 90vh;
        background-color: var(--main-bg-colour);
        background: linear-gradient(to bottom right, var(--main-bg-colour), #181a83);
        padding: 30px;
        box-shadow: 0px 0px 17px 3px rgba(0, 0, 0, 0.288);

    }
    .info-modal-tc{
        height: 72vh;
        overflow-y: scroll;
        text-align: left;
        line-height: 30px;
        border: 2px solid var(--main-accent-colour);
        padding: 15px;
        margin: 0;
    }
    .info-modal-top-bar{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .info-modal-close-button{
        font-size: 25px;
        background-color: #910000;
        width: 40px;
        margin-right: 0px;
        transition: 200ms;
    }
    .info-modal-close-button:hover{
        background-color: #d10000;
        cursor: pointer;
    }

@media (min-width:801px){
    .info-modal-content{
        height: 80vh;
    }
    .info-modal-tc{
        height: 65vh;
        padding: 25px;

    }
    .info-modal-close-button{

        margin-right: 15px;
    }
}