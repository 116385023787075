.about {
    margin: 100px 0;
    display: flex;
    flex-direction: column;
    width: 90%;
    align-self: center;
    z-index: 1;
}
.about-list {
    margin: 25px 0;
}

@media (min-width:801px){
    .about {
        width: 80%;
    }
}
