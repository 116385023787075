.sponsor-form {
    display: flex;
    flex-direction: column;
}
.sponsor-form select{
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 50px;
    font-size: 20px;
    font-weight: 200;
    background-color: #ffffff42;
    color: white;
    border: 2px solid var(--main-accent-colour);
    padding: 0 10px;
    margin: 10px 0;
}
.sponsor-book-form{
    border-top: 1px dashed var(--main-accent-colour);
    margin-top: 20px;
}

@media (min-width:801px){
    .sponsor-book-form input{
        width: 97%
    }
}